<template>
  <div class="pa-0">
    <div
      class="mt-15 px-8"
      v-if="
        isLoadLegal ||
        isLoadBusinessProcess ||
        isLoadSafeguard ||
        isLoadServicesStandard
      ">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="grid justify-items-center px-0 py-10" v-else>
      <v-col cols="11" class="pa-0 mt-10 pt-10">
        <v-row class="px-3">
          <v-col cols="12" class="text-left mb-10">
            <span class="font-bold text-3xl text-capitalize" v-if="bahasa"
              >Tata Kelola</span
            >
            <span class="font-bold text-3xl text-capitalize" v-else
              >Governance</span
            >
          </v-col>
        </v-row>
        <v-tabs v-model="tab" class="stories max-w-md md:max-w-7xl" show-arrows>
          <v-tab href="#tab-legal-basis" class="">
            <span v-if="bahasa">Basis Hukum</span>
            <span v-else>Legal Basis</span>
          </v-tab>
          <v-tab href="#tab-business-process" class="">
            <span v-if="bahasa">Proses Bisnis</span>
            <span v-else>Business Process</span>
          </v-tab>
          <v-tab href="#tab-safeguard" class="">
            <span v-if="bahasa">Safeguard</span>
            <span v-else>Safeguard</span>
          </v-tab>
          <v-tab href="#tab-services-standard" class="">
            <span v-if="bahasa">Standar Layanan</span>
            <span v-else>Services Standard</span>
          </v-tab>
        </v-tabs>
      </v-col>
      <div class="px-10">
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-legal-basis" class="pa-0">
            <v-row class="mt-3" v-if="isLoadLegal">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="!isLoadLegal && itemsLegal.length > 0">
              <v-col cols="12" sm="6" class="text-start mx-auto">
                <div class="mt-5 mb-10 mx-auto">
                  <div
                    class="font-bold text-base sm:text-xl my-5 text-left"
                    v-if="bahasa">
                    Basis Hukum BPDLH
                  </div>
                  <div
                    class="font-bold text-base sm:text-xl my-5 text-left"
                    v-else>
                    Legal basis of BPDLH
                  </div>
                  <div
                    class="font-lora text-base mb-3 cursor-pointer"
                    v-for="(item, index) in itemsLegal"
                    :key="index"
                    @click="downloadFile(item)">
                    <u>{{ item.name }}</u>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-business-process" class="pa-0">
            <v-row class="mt-3" v-if="isLoadBusinessProcess">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-3" v-else>
              <v-col cols="12" sm="8" class="mx-auto">
                <v-card class="pt-0" flat>
                  <v-card-text class="pt-0 px-0">
                    <div
                      class="text-black mb-0 pb-0 text-xl sm:text-2xl font-bold">
                      {{ this.itemsBusinessProcess.name }}
                    </div>
                    <img
                      v-if="this.itemsBusinessProcess.image"
                      :src="
                        this.baseUrlImage +
                        this.itemsBusinessProcess.image.file_path
                      "
                      @error="
                        $event.target.src = 'https://picsum.photos/300/200'
                      "
                      width="100%"
                      class="rounded-md mt-8" />
                    <div v-if="this.itemsBusinessProcess !== null">
                      <div
                        xs12
                        class="text-black font-lora pt-5 px-0 text-base"
                        v-html="this.itemsBusinessProcess.desc"></div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-safeguard" class="pa-0">
            <v-row class="mt-3" v-if="isLoadSafeguard">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="
                !isLoadSafeguard && Object.keys(itemsSafeguard).length > 0
              ">
              <v-col cols="10" sm="6" class="mx-auto">
                <v-card class="pt-0 mt-5" flat>
                  <v-card-text class="pt-0 px-0 text-start">
                    <div
                      class="text-black mb-0 pb-0 text-xl sm:text-2xl font-bold">
                      {{ itemsSafeguard.title }}
                    </div>
                    <v-skeleton-loader
                      v-if="isLoadSafeguard"
                      type="paragraph"
                      class="mt-5"></v-skeleton-loader>
                    <div v-if="itemsSafeguard.description !== null">
                      <div
                        xs12
                        class="text-black pt-5 px-0"
                        v-html="itemsSafeguard.description"
                        v-if="is_expand"></div>
                      <div
                        xs12
                        class="text-black pt-5 px-0"
                        v-html="strLimit(itemsSafeguard.description, 500)"
                        v-else></div>
                      <div
                        class="text-primary cursor-pointer font-bold mt-3 mb-2"
                        @click="is_expand = !is_expand"
                        v-if="
                          is_expand && itemsSafeguard.description.length > 500
                        ">
                        <u v-if="bahasa">Sembunyikan Detail</u>
                        <u v-else>Collapse Details</u>
                        <v-icon small color="green darken-1"
                          >expand_less</v-icon
                        >
                      </div>
                      <div
                        class="text-primary cursor-pointer font-bold mt-3 mb-2"
                        @click="is_expand = !is_expand"
                        v-else-if="
                          !is_expand && itemsSafeguard.description.length > 500
                        ">
                        <u v-if="bahasa">Perluas Detail</u>
                        <u v-else>Expand Details</u>
                        <v-icon small color="green darken-1"
                          >expand_more</v-icon
                        >
                      </div>
                    </div>
                    <div
                      class="text-black mt-8 pb-0 text-xl sm:text-xl font-bold"
                      v-if="itemsSafeguard.subCategory">
                      {{ bahasa ? "Sub kategori" : "Sub Category" }}
                    </div>
                    <v-row
                      v-for="(i, index) in itemsSafeguard.subCategory"
                      :key="index">
                      <v-col
                        cols="12"
                        sm="6"
                        lg="8"
                        class="text-black font-lora pt-5 text-base">
                        {{ i.title || "-" }}
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        lg="4"
                        class="pt-5 text-base text-right">
                        <u class="link cursor-pointer" @click="redirect(i)"
                          >View direct link</u
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-services-standard" class="pa-0">
            <v-row class="mt-3" v-if="isLoadServicesStandard">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="
                !isLoadServicesStandard &&
                Object.keys(itemsServicesStandard).length > 0
              ">
              <v-col cols="10" sm="6" class="mx-auto">
                <v-card class="pt-0 mt-5" flat>
                  <v-card-text class="pt-0 px-0 text-start">
                    <div
                      class="text-black mb-0 pb-0 text-xl sm:text-2xl font-bold">
                      {{ itemsServicesStandard.title }}
                    </div>
                    <v-skeleton-loader
                      v-if="isLoadServicesStandard"
                      type="paragraph"
                      class="mt-5"></v-skeleton-loader>
                    <div v-if="itemsServicesStandard.description !== null">
                      <div
                        xs12
                        class="text-black pt-5 px-0"
                        v-html="itemsServicesStandard.description"
                        v-if="is_expand_ss"></div>
                      <div
                        xs12
                        class="text-black pt-5 px-0"
                        v-html="
                          strLimit(itemsServicesStandard.description, 500)
                        "
                        v-else></div>
                      <div
                        class="text-primary cursor-pointer font-bold mt-3 mb-2"
                        @click="is_expand_ss = !is_expand_ss"
                        v-if="
                          is_expand_ss &&
                          itemsServicesStandard.description.length > 500
                        ">
                        <u v-if="bahasa">Sembunyikan Detail</u>
                        <u v-else>Collapse Details</u>
                        <v-icon small color="green darken-1"
                          >expand_less</v-icon
                        >
                      </div>
                      <div
                        class="text-primary cursor-pointer font-bold mt-3 mb-2"
                        @click="is_expand_ss = !is_expand_ss"
                        v-else-if="
                          !is_expand_ss &&
                          itemsServicesStandard.description.length > 500
                        ">
                        <u v-if="bahasa">Perluas Detail</u>
                        <u v-else>Expand Details</u>
                        <v-icon small color="green darken-1"
                          >expand_more</v-icon
                        >
                      </div>
                    </div>
                    <div
                      class="text-black mt-8 pb-0 text-xl sm:text-xl font-bold"
                      v-if="itemsServicesStandard.subCategory">
                      {{ bahasa ? "Sub kategori" : "Sub Category" }}
                    </div>
                    <v-row
                      v-for="(i, index) in itemsServicesStandard.subCategory"
                      :key="index">
                      <v-col
                        cols="12"
                        sm="6"
                        lg="8"
                        class="text-black font-lora pt-5 text-base">
                        {{ i.title || "-" }}
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        lg="4"
                        class="pt-5 text-base text-right">
                        <u class="link cursor-pointer" @click="redirect(i)"
                          >View direct link</u
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      is_expand: false,
      is_expand_ss: false,
      page: 1,
      pageCount: 0,
      per_page: 10,
      tab: null,
      isLoadLegal: false,
      isLoadBusinessProcess: false,
      isLoadSafeguard: false,
      isLoadServicesStandard: false,
      isLoadDetail: false,
      itemsLegal: [],
      itemsBusinessProcess: [],
      itemsSafeguard: [],
      itemsServicesStandard: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
    };
  },
  computed: {
    pageName() {
      let path = this.$route.name;
      let page = path.split("/").join("");
      return page.includes("-") ? page.split("-").join(" ") : page;
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  watch: {
    bahasa() {
      this.getDataLegal();
      this.getDataBusinessProcess();
      this.getDataSafeguard();
      this.getDataServicesStandard();
    },
    page() {},
    pageBlog() {
      this.getDataSafeguard();
    },
    length(val) {
      this.tab = val - 1;
    },
  },
  mounted() {
    if (window.location.hash.length > 0) {
      switch (window.location.hash) {
        case "#tab-legal-basis":
          this.tab = "tab-legal-basis";
          break;
        case "#tab-business-process":
          this.tab = "tab-business-process";
          break;
        case "#tab-safeguard":
          this.tab = "tab-safeguard";
          break;
        case "#tab-services-standard":
          this.tab = "tab-services-standard";
          break;

        default:
          this.tab = null;
          break;
      }
    }
    this.getDataLegal();
    this.getDataBusinessProcess();
    this.getDataSafeguard();
    this.getDataServicesStandard();
  },
  methods: {
    goTo(link, type, param) {
      this.$router.push({
        name: link,
        params: {
          type: type,
          id: param,
        },
      });
    },
    async getDataLegal() {
      this.isLoadLegal = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/law", { params: param })
        .then((response) => {
          this.itemsLegal = response.data.data.data;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadLegal = false));
    },
    async getDataBusinessProcess() {
      this.isLoadBusinessProcess = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/process", {
          params: param,
        })
        .then((response) => {
          this.itemsBusinessProcess = response.data.data;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadBusinessProcess = false));
    },
    async getDataSafeguard() {
      this.isLoadSafeguard = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(
          this.baseUrl +
            "/information_public/4e8df9e7-931f-438b-8283-a68f711283c5",
          { params: param }
        )
        .then((response) => {
          if (response.data.code === 200) {
            this.itemsSafeguard = response.data.data;
            this.getSubCategory("4e8df9e7-931f-438b-8283-a68f711283c5");
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadSafeguard = false));
    },
    async getDataServicesStandard() {
      this.isLoadServicesStandard = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(
          this.baseUrl +
            "/information_public/087e186e-a1fd-4485-80df-dd46c2afe5f1",
          { params: param }
        )
        .then((response) => {
          if (response.data.code === 200) {
            this.itemsServicesStandard = response.data.data;
            this.getSubCategoryServicesStandard(
              "087e186e-a1fd-4485-80df-dd46c2afe5f1"
            );
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadServicesStandard = false));
    },
    async getSubCategory(id) {
      this.isLoadDetail = true;
      await axios
        .get(this.baseUrl + "/sub_information_public/" + id, {
          params: { lang: this.language },
        })
        .then((response) => {
          this.itemsSafeguard.subCategory = response.data.data.data;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadDetail = false));
    },
    async getSubCategoryServicesStandard(id) {
      this.isLoadDetail = true;
      await axios
        .get(this.baseUrl + "/sub_information_public/" + id, {
          params: { lang: this.language },
        })
        .then((response) => {
          this.itemsServicesStandard.subCategory = response.data.data.data;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadDetail = false));
    },
    redirect(val) {
      let url = val.url ? val.url : this.baseUrlImage + val.image.file_path;
      window.open(url, "_blank");
    },
    downloadFile(item) {
      let url = item.url ? item.url : this.baseUrlImage + item.image.file_path;
      window.open(url, "_blank");
    },
  },
};
</script>
